import {
  FaUserGraduate,
  FaRegStar,
  FaHourglassEnd,
  FaRegCheckCircle,
} from "react-icons/fa";

export const features = [
  {
    id: 1,
    title: "AI-Optimized Resumes",
    body: "Our artificial intelligence algorithm analyzes your resume and LinkedIn profile, suggesting targeted improvements to showcase your unique skills and experience.",
    color: "ceevee.blue",
  },
  {
    id: 2,
    title: "Tailored Cover Letters",
    body: "Create the perfect cover letter for each job application with our AI-driven content generation, making you stand out from the competition.",
    color: "ceevee.purple",
  },
  {
    id: 3,
    title: "Personalized Interview Preparation",
    body: "Boost your confidence with our AI chatbot, simulating real interview scenarios and offering personalized feedback on your performance.",
    color: "ceevee.yellow",
  },
  {
    id: 3,
    title: "Position Matching & Optimization",
    body: "Discover the most suitable positions for your profile and optimize your resume for those desired positions, ensuring the perfect match.",
    color: "ceevee.green",
  },
];

export const benefits = [
  {
    id: 1,
    body: "Receive personalized interview coaching to boost your confidence and success.",
    icon: FaUserGraduate,
  },
  {
    id: 2,
    body: "Stand out from the competition with a unique and compelling resume.",
    icon: FaRegStar,
  },
  {
    id: 3,
    body: "Save time and effort by streamlining the application process.",
    icon: FaHourglassEnd,
  },
];

export const questions = [
  {
    id: 1,
    question: "How does CeeVee ensure my resume is optimized for ATS checkers?",
    answer:
      "Our AI algorithms are specifically designed to evaluate and optimize your resume based on current ATS requirements. By incorporating the best formatting practices and keyword optimization, we help you create a resume that is more likely to pass through ATS checkers successfully.",
  },
  {
    id: 2,
    question:
      "Will my cover letter still feel personal and authentic even if it's generated using AI?",
    answer:
      "Absolutely! Our AI generates content based on your resume and job preferences, ensuring that each cover letter is tailored to you and the position you're applying for. This results in a personal, engaging, and authentic cover letter that showcases your unique skills and personality. If you are not sure, know that the content of this website was generated using artificial intelligence 🙂",
  },
  {
    id: 3,
    question:
      "Is CeeVee suitable for people who are new to the job market or making a career change?",
    answer:
      "Absolutely! CeeVee is designed to support job seekers at all stages of their careers, whether you're a recent graduate, a seasoned professional, or someone transitioning into a new field. Our AI-powered features will help you craft a resume and cover letter that showcase your transferable skills and make you stand out in your new industry.",
  },
  {
    id: 4,
    question: "How does CeeVee help me prepare for job interviews?",
    answer:
      "CeeVee's AI chatbot simulates real interview scenarios and provides personalized feedback on your responses. This interactive interview preparation allows you to practice answering common and challenging interview questions, building your confidence and improving your overall interview skills.",
  },
  {
    id: 5,
    question: "Will my personal information be secure with CeeVee?",
    answer:
      "We take data privacy very seriously at CeeVee. Your personal information is encrypted and securely stored on our platform. We will never share or sell your data without your explicit consent. For more details, please review our Privacy Policy.",
  },
  {
    id: 6,
    question: "Can I customize my resume design using CeeVee?",
    answer:
      "Yes! CeeVee offers a variety of resume templates and design options that you can choose from. Our AI also provides suggestions on the best design elements for your specific industry and job type, ensuring your resume is visually appealing and professional.",
  },
  {
    id: 7,
    question: "How can I get started with CeeVee?",
    answer:
      "To get started, simply subscribe to our waiting list, and we'll notify you as soon as CeeVee is ready to launch. Once the platform is live, you'll be able to create an account, upload your resume or connect your LinkedIn profile, and begin optimizing your job search with our AI-powered tools.",
  },
  {
    id: 8,
    question: "Is CeeVee suitable for job seekers in different countries?",
    answer:
      "Yes, CeeVee is designed to assist job seekers worldwide. Our AI algorithms consider regional differences and preferences in resume formatting, job application processes, and interview expectations. This ensures that your job search is optimized for the country and industry you're targeting.",
  },
  {
    id: 9,
    question: "Will CeeVee support job seekers in various industries?",
    answer:
      "Absolutely! CeeVee is adaptable to various industries and job types, as our AI algorithms are designed to identify and highlight industry-specific skills, requirements, and trends. Regardless of your field, CeeVee will help you create a compelling and relevant resume, cover letter, and interview strategy.",
  },
];
