import { useState, useRef } from "react";
import customTheme from "./customTheme.js";
import {
  Alert,
  AlertIcon,
  Spinner,
  ChakraProvider,
  Button,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Box,
  Grid,
  GridItem,
  Flex,
  Image,
  Icon,
  Card,
  CardHeader,
  CardBody,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";

import CustomIcon from "./Logo.jsx";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "./utils/firebase";
import HeroLogo from "./assets/herologo.svg";
import { features, benefits, questions } from "./constants/text.js";

const App = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const emailRef1 = useRef();
  const emailRef2 = useRef();

  const addPost = async () => {
    const collectionRef = collection(db, "emails");
    try {
      await addDoc(collectionRef, {
        email: emailRef1.current.value || emailRef2.current.value,
        timeStamp: serverTimestamp(),
      });
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setError(err.message);
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setError("");

    if (!emailRef1?.current?.value && !emailRef2?.current?.value) {
      setError("Please enter a valid email address.");
      return;
    }

    setLoading(true);
    addPost();
  };
  return (
    <ChakraProvider theme={customTheme}>
      <Flex
        as='nav'
        align='center'
        justify='space-between'
        wrap='wrap'
        p={6}
        color={"linkedin.500"}
        width={"full"}
      >
        <Box
          as='a'
          href='/'
          display='flex'
          alignItems='center'
          fontSize='xl'
          fontWeight={"bold"}
          color='#000080'
          gap='8px'
        >
          <img src='./ceevee-logo.png' alt='logo' width='50px' height='50px' />
          CeeVee
        </Box>
      </Flex>
      <Flex
        as='main'
        maxW={1000}
        mx={[4, 12, 15, "auto"]}
        mt={[50, 100, 200]}
        color={"blackAlpha.800"}
        direction={"column"}
      >
        <Grid
          as='header'
          templateColumns={["1fr", "1fr", "1fr 1fr"]}
          alignItems={"center"}
        >
          <GridItem
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
            gap={6}
          >
            <Heading
              bgGradient='linear(to-r, #005E93, #34B3E4)'
              bgClip='text'
              fontSize={{ base: "4xl", lg: "6xl" }}
              fontWeight='extrabold'
            >
              Empower Your Career Journey
            </Heading>
            <Text fontWeight={"semibold"} fontSize={18} lineHeight={7}>
              Revolutionize your job search with AI-powered resume optimization,
              tailored cover letters, and personalized interview preparation.
            </Text>
            <Text fontWeight={"semibold"} fontSize={18} lineHeight={7}>
              We are currently in beta, working B2B, and will be launching our
              B2C product soon.
            </Text>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabel>Subscribe Now and Be the First:</FormLabel>
                <Flex gap={2} mt={4}>
                  {!success ? (
                    <>
                      <Input
                        placeholder='example@email.com'
                        type='email'
                        required
                        ref={emailRef1}
                      />
                      {!loading ? (
                        <Button type='submit' colorScheme='linkedin'>
                          Subscribe
                        </Button>
                      ) : (
                        <Flex justifyContent='center'>
                          <Spinner />
                        </Flex>
                      )}
                    </>
                  ) : (
                    <Alert status='success'>
                      <AlertIcon />
                      Thank you for subscribing!
                    </Alert>
                  )}
                </Flex>
                {error && (
                  <Alert mt={4} status='error'>
                    <AlertIcon />
                    {error}
                  </Alert>
                )}
              </FormControl>
            </form>
          </GridItem>
          <GridItem
            placeSelf={"end"}
            alignSelf={"center"}
            justifySelf={["center", "center", "end"]}
            width={[200, 300, "full"]}
            mt={[50, 100, 0]}
          >
            <Image
              src={HeroLogo}
              alt='half brain half machine'
              fill={"#333"}
              mx={["auto", "autp", "null"]}
            />
          </GridItem>
        </Grid>
        <Grid
          as='section'
          templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
          mx='auto'
          gap={12}
          mt={[50, 100, 200]}
        >
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <Heading textAlign={"center"}>Features</Heading>
          </GridItem>
          {features.map((feature) => (
            <GridItem key={feature.id}>
              <Box
                borderLeft={"5px solid"}
                borderColor={feature.color}
                padding={8}
                boxShadow={"xl"}
                h='full'
              >
                <Flex alignItems={"center"} gap={2} mb={8}>
                  <Heading size='md' as='h3'>
                    {feature.title}
                  </Heading>
                </Flex>
                <Text fontWeight={"semibold"} lineHeight={7}>
                  {feature.body}
                </Text>
              </Box>
            </GridItem>
          ))}
        </Grid>
        <Grid
          as='section'
          columnGap={4}
          rowGap={12}
          mt={[50, 100, 200]}
          templateColumns={{ base: "1fr", md: "repeat(3,1fr)" }}
        >
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <Heading textAlign={"center"}>Benefits</Heading>
          </GridItem>
          {benefits.map((benefit) => (
            <Card
              key={benefit.id}
              border='1px solid'
              borderColor={"linkedin.500"}
              alignItems={"center"}
              size={"md"}
              backgroundColor={"linkedin.50"}
              paddingY={4}
            >
              <CardHeader>
                <Icon as={benefit.icon} boxSize={"14"} />
              </CardHeader>
              <CardBody>
                <Text textAlign={"center"} lineHeight={7}>
                  {benefit.body}
                </Text>
              </CardBody>
            </Card>
          ))}
        </Grid>
        <Box as='section' mt={[50, 100, 200]}>
          <Heading textAlign={"center"} as='h2' size={"lg"}>
            Common Questions
          </Heading>
          <Accordion
            mt={12}
            mb={[50, 100, 200]}
            defaultIndex={[0]}
            allowMultiple
            maxW={"900px"}
            mx='auto'
          >
            {questions.map((question) => (
              <AccordionItem key={question.id}>
                <h2>
                  <AccordionButton fontWeight={"semibold"}>
                    <Box as='span' flex='1' textAlign='left'>
                      {question.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>{question.answer}</AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
        <Flex
          direction='column'
          as='section'
          mx='auto'
          gap={6}
          maxW={"600px"}
          mb={[50, 100, 200]}
        >
          <Heading
            size={"md"}
            fontSize={"24px"}
            textAlign={"center"}
            lineHeight={1.4}
          >
            Don't miss your chance to revolutionize your job search with
            CeeVee's AI-powered platform!
          </Heading>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel>
                Subscribe Now and Be the First to Experience CeeVee!
              </FormLabel>
              <Flex gap={2} mt={4}>
                {!success ? (
                  <>
                    <Input
                      placeholder='example@email.com'
                      type='email'
                      required
                      ref={emailRef2}
                    />
                    {!loading ? (
                      <Button type='submit' colorScheme='linkedin'>
                        Subscribe
                      </Button>
                    ) : (
                      <Flex justifyContent='center'>
                        <Spinner />
                      </Flex>
                    )}
                  </>
                ) : (
                  <Alert status='success'>
                    <AlertIcon />
                    Thank you for subscribing!
                  </Alert>
                )}
              </Flex>
              {error && (
                <Alert mt={4} status='error'>
                  <AlertIcon />
                  {error}
                </Alert>
              )}
            </FormControl>
          </form>
        </Flex>
      </Flex>
      <Box as='footer' bg='gray.100' p={6}>
        <Flex direction={"column"} alignItems={"center"} gap={2}>
          <Heading as='h3' size='md'>
            CeeVee
          </Heading>
          <Text>All rights reserved &copy; {new Date().getFullYear()}</Text>
        </Flex>
      </Box>
    </ChakraProvider>
  );
};

export default App;
