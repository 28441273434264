import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  colors: {
    ceevee: {
      blue: "#66BCF2",
      yellow: "#E5C07B",
      purple: "#BB77D9",
      green: "#9CBF78",
    },
  },
});

export default customTheme;
